#table-list thead, #table-list tbody tr {
    /* display: table;
    width: 100%;
    table-layout: fixed; */
}
#table-list thead th{
    position: sticky;
    top: 0;
    background-color: #f6f7f9;
    z-index: 2;
    /* box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4); */
}
#table-list thead {
    background-color: rgba(0, 0, 0, 0.04);
}
.css-checkbox.css-control-sm .css-control-input ~ .css-control-indicator{
    /* border-radius: 50%; */
}
#table-list .checkbox{
    width: 50px;
}
#page-container.page-header-fixed #main-container{
    height: 100vh;
    overflow: hidden;
}
#page-footer{
    display: none;
}
.content, .block, .block-content{
    height: 100%!important;
}
.table-responsive{
    /* overflow: hidden; */
    max-height: 100%;
}
#table-list{
    height: 100%;
    position: relative;
}
#table-list tbody{
    /* position: absolute;
    left: 0;
    top: 41px;
    height: 100%;
    overflow: auto;
    padding-bottom: 60px; */
}
.h100{
    height: 100%;
    max-height: 100%;
    overflow: hidden;
}
.block-content-m{
    display: flex;
    flex-direction: column;
}
#page-container .content-header{
    padding-top: 0px!important;
    padding-bottom: 0px!important;
    height: 40px!important;
}
#main-container{
    padding-top: 40px!important;
}
.menu-folder{
    height: 100%;
    overflow: auto;
}
.menu-folder .head-menu{
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    user-select: none;
}
.menu-folder .head-menu span{
    vertical-align: baseline;
}
.menu-folder > ul > li > .ant-menu-submenu-title, .menu-folder > ul > .ant-menu-item-only-child{
    /* padding-left: 0px!important; */
}
.menu-folder .ant-menu{
    border-right: 0px;
}
.btn-folder-box{
    display: flex;
    align-items: center;
}
.btn-folder-box .btn{
    height: 35px!important;
    width: 35px;
}
.ant-notification{
    /* z-index: 9999; */
}
#sidebar, #page-header{
    z-index: 99!important;
}
#table-list th, #table-list td{
    word-break: break-all;
}

.export-container{
    background-color: #fff;
    padding: 10px;
    box-shadow: 0 0 0 1px rgba(63,63,68,.05), 0 1px 3px 0 rgba(63,63,68,.15);
    border-radius: 3px!important;
    margin-bottom: 10px;
    min-height: 100px;
}
.export-option{
    box-shadow: 0 0 0 1px rgba(63,63,68,.05), 0 1px 3px 0 rgba(63,63,68,.15);
    border-radius: 5px;
    padding: 5px;
    margin-bottom: 10px;
    background: #f7f7f7;
    text-align: center;
    padding-bottom: 15px;
}
.export-option .title{
    justify-content: space-around;
    width: 100%;
    text-align: center;
    margin-top: 10px;
    display: flex;
}
.export-option .title .ant-btn{
    display: flex;
    align-items: center;
}
.export-option .ant-btn:hover, .export-option .ant-btn:focus {
    color: rgba(0, 0, 0, 0.65);
    background-color: #fff;
    border-color: #d9d9d9;
}
.export-option .ant-btn.active{
    color: #40a9ff;
    background-color: #fff;
    border-color: #40a9ff;
}
.tool-filter .ant-btn{
    display: flex;
    align-items: center;
}
.btn-middle{
    height: 32px;
    padding: 4px 15px;
    display: flex;
    align-items: center;
}
@keyframes fadeIn {
	0% {
		transform: translateY(-25%);
	}
	50%{
		transform: translateY(4%);
	}
	65%{
		transform: translateY(-2%);
	}
	80%{
		transform: translateY(2%);
	}
	95%{
		transform: translateY(-1%);
	}			
	100% {
		transform: translateY(0%);
	}		
}

.popup {
  animation-name: fadeIn;
  animation-duration: 0.4s;
  background-clip: padding-box;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  left: 0px;
  top: 0px;
  overflow: hidden;
  -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 99;
  position: fixed;
}
.popup ul{
  list-style-type: none;
  margin: 0;
  outline: none;
  padding: 0;
  text-align: left;
  min-width: 120px;
}
.popup li {
  clear: both;
  color: rgba(0, 0, 0, 0.65);
  cursor: pointer;
  font-size: 14px;
  font-weight: normal;
  line-height: 22px;
  margin: 0;
  padding: 5px 12px;
  transition: all .3s;
  white-space: nowrap;
  -webkit-transition: all .3s;
}

.popup li:not(.title):hover {
  background-color: #e6f7ff;
}

.popup li > i {
  margin-right: 8px;
}
.popup li.title{
    border-bottom: 1px solid #dadada;
    font-weight: bold;
    font-size: 13px;
}
.loading-spin{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
}
.ant-tree-node-content-wrapper{
    display: flex;
    align-items: center;
}
.ant-tree-switcher{
    display: flex;
    align-items: center;
    justify-content: center;
}
.ant-tree-iconEle{
    display: flex!important;
    align-items: center;
    justify-content: center;
}
.ant-tree-title{
    max-width: 86%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.ant-tree-node-content-wrapper{
    overflow: hidden;
    text-overflow: ellipsis;
}
.ant-pagination-item-link{
    display: flex!important;
    align-items: center;
    justify-content: center;
}
.ant-form-item-label{
    width: 100%;
    text-align: left;
}
.form-check-token{
    /* background: #dadada; */
}